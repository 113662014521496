 /* .heading__capabilityPage{
    font-size: 29px;
} */
.capabilityPage__headingPara{
    margin-top: 10px;
}


@media only screen and (max-width: 1370px){
    .heading__capabilityPage{
        font-size: 30px;
    }
      .capabilityPage__headingPara{
        font-size: 15px !important;
      
      }

    
    }