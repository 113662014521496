.link {
  color: black;
  text-decoration: none;
}

.table thead > tr > th {
  vertical-align: bottom;

  border-bottom: 2px solid #dddddd;
}

.main {
  padding: 15px;
  background-color: #fff;
  margin-left: 20%;
}

.sidebar {
  width: 15%;
  height: 100vh;
}
.sidebar {
  /* position: -webkit-sticky; */
  position: fixed;
  top: 100px;
  float: left;
  /* z-index: 20; */
}
.side-bar-link {
  text-decoration: none;
  font-size: 18px;
  color: #878787;
  display: inline-block;
  margin: 0px;
  width: 100%;
  padding: 10px;
  padding-left: 50px;
}
.add_reading_link{
  text-decoration: none;
}
.add_reading_link:hover {
text-decoration: underline;
}
.side-bar-link:hover {
  color: #545454;
  background-color: #ededed;
}
/* @media only screen and (max-width: 1285px) {
  .table-width-md-screen{
    width: 70% !important;
  }
} */
