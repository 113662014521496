.topContainer {
    display: flex;
    flex-direction: column;

    align-items: center;
    /* min-height: 100vh; */
    margin-top: 60px;
}

.urlInfoForm {
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: center;
    min-height: 100vh;
    margin-top: 80px;
}

.formsContainer {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    background-color: white;
    margin-top: -46px;
    padding-left: 6%;
}

.formContainer {
    width: 100%;
    border: 2px;
    border-radius: 5px;
    padding: 30px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    text-align: left;
    font-size: 20px;
    margin-top: -60px !important;
    background-color: white;
}

.formContentDiv {
    display: flex;
    flex-direction: column;
}

.urlOnBoardFormContainer {
    /* width: 70%; */
    /* width: 50%; */
    border: 2px;
    border-radius: 5px;
    padding: 30px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    text-align: left;
    font-size: 20px;
    min-height: 75%;
}

.formLabel {
    margin-bottom: 10px;

}

.formGroup {
    display: flex;
    flex-direction: column;
    /* display: grid;
    grid-template-columns: auto auto;
    column-gap:10px */
}

.formUrlGroup {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* min-height: "100px"; */
    max-height: 450px;
    overflow-y: scroll;
    padding-top: 20px;
}

.inputBox {
    margin-bottom: 30px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: 1px;
    border-color: rgba(0, 0, 0, .55);
    border-radius: 5px;
    font-size: 16px;
    height: 30px;
    font-family: "Maersk Text";
}

.multiLineInput {
    margin-bottom: 50px;
    font-size: 15px;
    padding: 10px;
    border-radius: 5px;
    font-family: "Maersk Text";
}

.selectButton {
    width: min-content !important;
    border: none !important;
    margin-block-end: auto !important;
}

.selectButton::after {
    box-shadow: none !important
}

.operationsContainer {

    top: 158px;

    left: 312px;
    border: 2px;
    border-radius: 5px;

    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    text-align: left;
    font-size: 20px;
    padding: 10px;

    display: flex;

    justify-content: space-between;
    margin-bottom: 30px;
    width: 32%;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.submitButton {
    background-color: #B5E0F5;
    border: none;
    color: black;
    width: 15%;
    font-size: 20px;
    font-family: "Maersk Text";
    padding: 10px;
    border-radius: 5px;

}

.submitButton:hover {

    background-color: #00243D;
    color: #FFF;
}

.disabledButton {
    background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3));
    color: -internal-light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3));
    border-color: -internal-light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3));
    border: none;
    color: black;
    width: 30%;
    font-size: 20px;
    font-family: "Maersk Text";
    padding: 10px;
    border-radius: 5px;
}

h1 {
    margin-bottom: 40px;
}

@media only screen and (max-width: 1370px) {
    .topContainer {
        margin-top: 65px;
    }

    .formContainer {
        font-size: 15px;
    }

    .inputBox {
        margin-bottom: 25px;
        font-size: 13px;
    }
}

ul {
    max-height: 150px !important;
}