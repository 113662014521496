*{
    box-sizing: border-box;
}
.sloInfo__spinner{
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
}
.sloinfoform__input{
border: 1px solid #CFCFCF;
border-radius: 4px;
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
height: 40px;
padding: 10px;
font-size: 15px;


}
.sloinfoform{
    width:60%;
    /* border: 1px solid #DCDCDC; */
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    /* border: 1px solid #B5E0F5; */
    border-radius: 10px;
}

.sloinfoform__inputContainer{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}
.sloinfoform__label{
margin-bottom: 20px;
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 24px;
margin-right: auto;
color: #141414;
}


.choiceDiv__container{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
.sloinfoform__ChoiceDivUnselected{
    background-color: #EDEDED;
    width:20%;
    text-align: center;
    padding: 10px;
    -moz-border-radius: 100px / 60px;
    -webkit-border-radius: 100px / 60px;
    border-radius: 100px / 60px;
    cursor: pointer;

}
.sloinfoform__ChoiceDivSelected{
    background-color: #058EC6;
    width:20%;
    text-align: center;
    padding: 10px;
    -moz-border-radius: 100px / 60px;
    -webkit-border-radius: 100px / 60px;
    border-radius: 100px / 60px;
    cursor: pointer;
    color:white;

}
.sloinfoform__submitButtonContainer{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
}
.sloinfoform__submitButton{
width: 21%;
font-size: 15px;
background-color: #B5E0F5;
border: none;
padding: 15px;
border-radius: 10px;
cursor: pointer;
margin-top: 20px;
}
.sloinfoform__submitButton:hover:enabled{
    background-color: #003D6D;
    color:white;
    cursor: pointer;
}
.invalidInput{
    border-color: red;
}
.sloth__sloinfoformInfo {
    font-size: 10px;
    display: inline-block;
    margin-left: 5px;
}

.sloinfoform__contentArranger{
    display: flex;
    justify-content: space-between;
    
}
.sloinfoform__sloinfoContainer{

    border: 1px solid #CFCFCF;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.sloinfo__slosConatinerWithNoSlos {
    width: 100%;
    height: 20rem;
    margin-top: 50px;
    background-color: #EDEDED;
    border-radius: 20px;
    color: #B2B2B2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
 
    right: 40px;
}
.sloinfo__slosContainer{
    width: 100%;
    height: 20rem;
    margin-top: 50px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 20px;
    overflow-y: scroll;

    right: 40px;

}
.sloinfo__sloContainer{
width: 100%;
color: #141414;
border: 1px solid #B2B2B2;
/* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
background-color: #B5E0F5;
font-size: 15px;
padding: 10px;
border-radius: 10px;
margin-bottom: 20px;
display: flex;
justify-content: space-between;
align-items: center;

}
.sloinfo__sloDownTimeDivContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    margin-top: 10px;
    
}
.sloinfo__sloDownTimeDiv{
    background-color: #B5E0F5;
    /* color: white; */
    padding: 10px;
    border-radius:10px ;

}

/* src/Popup.css */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    min-width: 300px;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .mandatory {
    color: red;
  }

  .sloinfoform__auto_query_label{
    background-color: #B5E0F5;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-size: 16px;
    cursor: pointer;
    line-height: 1px;
    width: 25%;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
    }

 .sloinfoform__auto_label{
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin-left: auto;
    /* margin-right: auto; */
    color: #141414;
}

.sloinfoform__auto_query_popup_label{
    background-color: #B5E0F5;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-size: 18px;
    cursor: pointer;
    line-height: 2px;
    width: 16%;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
}

.sloinfoform__auto_button_query_label{
    background-color: #B5E0F5;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-size: 15px;
    cursor: pointer;
    line-height: 1px;
    width: 25%;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
    }
    .sloinfoform__autoquery_popup_label{
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin-right: auto;
        color: #141414;
    }

    .sloinfoform__autoquery_popup_label{
        background-color: #B5E0F5;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin-right: auto;
        color: #141414;
    }