.Tabs__tabBlueBackGround{
    background-color: #B5E0F5!important;;
}
.Tabs__tab{
    border-top-right-radius: 10px !important;
    border-top-left-radius:10px !important;
}
.sloth__sloUpdateButton:enabled{
    /* margin-top: 15px;
    margin-bottom: 20px; */
    margin-left: 10px;
    margin-bottom: 10px;
    background-color: #B5E0F5;
    border: none;
    color: black;
  
}

.sloth__sloUpdateButton:hover{
    
    background-color: #003D6D;
    color: white;
}

