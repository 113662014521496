@import '@anchor/react-components/dist/lib/css/global.css';

/* @import '@anchor/react-components/dist/lib/css/global.css'; */
/* body,html {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
} */
body {
  margin: 0;
  font-family: "Maersk Text";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.heading {
  font-family: "Maersk Headline";
  /* font-weight: 400; */

}

@import "~@maersk-global/fonts/maeu/fonts.css";

* {
  font-family: "Maersk Text", -apple-system, BlinkMacSystemFont, "Microsoft JhengHei",
    "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑", sans-serif !important;
}