.dropDown__fontSize{
    font-size: 20px;
    padding: 0px !important;
}
.dropdown-menu {
    height:max-content;
    
    padding: 0px !important;
    /* border-radius: 10px !important; */
 

}

.dropdown-item{
    padding:2px !important;
    border-bottom: 0.25px solid white;
    /* border-radius: 5px !important; */
}

/* .dropdown-menu.show {
    display: block;
} */

@media only screen and (max-width: 1370px){
    .dropDown__fontSize{
        font-size: 15px;
    
    }
    }