.Container{

    height: 100vh; 
    background-color: #058EC6;
}
.row1{
   
    align-items: center;
   
    width: 100%;
    justify-content: center;
    height: 90%;
}
.col1{
    padding-left: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
}
.image{
    width: 70%
}
.col2{
    display: flex;
    justify-content: center;
    padding-top: 7rem;
   
}

.buttonRow{
    padding-left: 10rem;
    padding-right: 10rem;
}
.getStartedButton{
    width:fit-content !important;
    background-color: #00243D !important; 
    color: #FFF !important;
    border: none !important;
    font-size: 20px !important
}
.getStartedButtonLink{
    text-decoration: none;
    color: #FFF !important;
}
.contributeButton{
    background-color: #FFF !important;
    color: black !important;
    font-size: 20px !important;
    width: fit-content !important
}
.contributeButtonLink{
    text-decoration: none;
    color: black!important;
}
.mainHeading{
    font-size: 3.5rem !important;
                margin-bottom: 2rem !important;
                font-weight: 400 !important;
}
.subHeading{
    font-size: 25px;
}

@media only screen and (max-width: 1285px) {
    h1{
        font-size: 40px;
    }
    .getStartedButton{
        width: 90% !important;
        font-size: 14px !important;
    }
    .contributeButton{
        width: 90% !important;
        font-size: 14px !important;
    }
    .subHeading{
        font-size: 20px;
    }
    .mainHeading{
        font-size: 3rem !important;
                margin-bottom: 2rem !important;
                font-weight: 400 !important;
    }
}