
.navbarStyle{
    padding: 20px 10px 10px 20px !important;
    background-color: #B5E0F5;
    }
    .nav-link{
      margin-left: 25px;
      font-weight: 300;
      }
      .dropdown:hover .dropdown-menu {

        display: block;
        
        margin-top: 0px;
        
        margin-left: 30px;
        
        background-color: #e9f6fc;
        
        border: none;
        
        color: white;
      }
    .contribute-navlink{
      font-weight: 600 !important;
    color: #005487 !important;
    }
    .contribute-navlink:hover{
      color: #003D6D !important;
    }
    /* @media only screen and (max-width: 1285px) {
      .fnt-md-screen{
        font-size: 10px ;
      } */
    /* } */
      @media only screen and (max-width: 1370px){
        .navbarStyle{
          padding: 10px 5px 5px 10px !important;
          font-size: 15px;
        }
        .dropdown:hover .dropdown-menu{
          font-size: 14px;
        }
      }