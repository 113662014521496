.forenform__topContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    /* justify-content: center;
    align-items: center; */
    /* margin-top: 100px;   */
    /* height: 100vh; */
}

#report{
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.foren__formContainer {
    width: 100%;
    border: 2px;
    border-radius: 5px;
    padding: 20px 20px 20px 20px;
    border: 1px solid #DCDCDC;
    font-size: 20px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.formLable {
    margin-bottom: 20px;


}

.foren__inputbox {
    margin-bottom: 8px;
}

.reportSection{
    margin-top: 40px;
    border: 2px solid #DCDCDC;
}

.inputBox {
    margin-bottom: 50px;
    /* border-top: none;
    border-left: none;
    border-right: none;  */
}

.selectButton {
    width: min-content !important;
    border: none !important;
    margin-block-end: auto !important;
}

.selectButton::after {
    box-shadow: none !important
}

.buttonContainer {
    display: flex;
    justify-content: center;
}

.forenform__submitButton {
    background-color: #B5E0F5;
    border: none;
    color: black;
    width: 16%;
    font-size: 20px;

}

.forenform__submitButton:hover {

    background-color: #00243D;
    color: #FFF;
}

h2 {
    margin-bottom: 30px;
}

.inputBox_Container {
    display: flex;
}

@media only screen and (max-width: 1370px) {


    .foren_formContainer_md {
        font-size: 15px;
    }

    .foren_inputBox_md {
        font-size: 13px;
        height: 30px;
    }

    .forenform__topContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* margin-top: 20px;   */
        /* height: 100vh; */
        /* height: fit-content; */
    }
}