/* .capabContainer{
    background: #EDEDED;
    border: 3px solid #eaf1f3;
   
  
    
} */
/* .link{
    color: black;
    text-decoration: none;
} */
/* .link:hover{
    color:#058EC6
}
.w-100{
    width:100%
} */
/* .card{
  
    margin:1px !important
    
} */
/* .topDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
} */
/* .aligningItems{
    display: flex;
    flex-direction: column;
    align-items: left;
} */
/* .cap-card-padding-add-lg-screen {
    padding-top: 1.5rem; 
    padding-bottom: 1.5rem 
} */
/* .cap-card-margin-add-lg-screen{

    margin-top: 1rem 
} */
/* .srelibrary-capabilitypage-link{
    text-decoration: none;
  font-size: 20px;
  color: #878787;
  display: inline-block;
  margin: 0px;
  width: 100%;

  padding-left: 30px;
  } */
  /* .srelibrary-capabilitypage-link:hover{
    color: #545454;
  background-color: #ededed;
  } */

  .capabilities__topContainer{
    background-color: #003D6D;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
  }
  .capabilities__contentWrapper{
    background-color: #91D8F2;
    border-radius: 5px;

  }

  .capabilities__container {
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row ;
    padding-bottom: 20px;
 
  }

  .capabilities__capabilityContainer{
    height:fit-content;
    width:32%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .capabilities__capabilityTagLine{
    font-size:18px;
    text-align: center;
    padding: 20px;
    
  }

  @media only screen and (max-width: 1370px){
    .capabilities__topContainer{
        background-color: #003D6D;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* display: block; */
        /* padding-top: 40px; */
        /* padding-bottom: 40px; */
    
      
      }
      .capabilities__contentWrapper{
        background-color: #91D8F2;
        margin-top: 60px;
      
      }
      .capabilities__capabilityTagLine{
        font-size:15px;
        text-align: center;
        padding: 20px;
        
      }

    
    }
