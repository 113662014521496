.ContentWrapperSection{
    padding-top: 2%;
    /* border: 1px solid #CFCFCF; */
    margin-top: 7%;
    margin-left: 34%;
    display: flex;
    flex-direction: column;
    /* text-align: center; */
    justify-content: center;
    align-items: center;
    width: 40vw;
}