.menu-item:hover {
    /* background-color: #91D8F2; */
    /* background-color: #cfe9f2; */
    background-color: #0CA0CE;
}

.menu-item {
    width: 100%;
    /* padding-left: 15px;
    padding-right: 15px; */
    padding: 8px;
    /* margin-top: 3%; */
    /* color: black; */
    color: white;
    border-bottom: 1px solid #005487;
    min-height: 40px;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.inner_child{
    border: none !important;
}

.home-menu-wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    height: 98vh;
    border-right: 1px solid #CFCFCF;
    width: 15%;
    margin-top: 4.5rem;
    /* background-color: #0073AB; */
    /* background-color: white; */
    background-color: #0073AB;
    padding-top: 0.4%;
}

.homeMenu {
    width: 100%;
    /* padding-left: 20px; */

}

@media only screen and (max-width: 1500px) {
    .home-menu-wrapper {
        margin-top: 4.2%;
        width: 13% !important;
        padding-top: 2.6%;
    }

    .menu-item {
        width: 100% !important;

    }
}

.extra_class {
    display: flex;
    justify-content: space-between;
}