.topContainerCert{
 
     display: flex;
     flex-direction: column;
     align-items: center;
     /* min-height: 100vh; */
     margin-top: -10px !important;
     align-items: center;
     /* border:2px solid red; */
    
}

.submitBtn{
    background-color: #00243D ;
    width: 20%;
}

.URL{
    width:100% ;
    border-top: none;
    border-left:none;
    border-right: none;
    
}
.certValidationForm{
    width: 100%;
}

.containDiv{
    width:500px;
}

.headingURL{
    color:#42B0D5;
     width:330px;
     text-align:center;
}
.SingleformContainer{
   background-color: #fff;
    border: 1px solid #EDEDED;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.04), 0px 6px 30px 5px rgba(0, 0, 0, 0.03), 0px 16px 24px 2px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    min-height: 30rem;
  }