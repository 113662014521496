.table-wrapper {
  height: max-content;
  overflow-y: auto;
  border: "none" !important;
}

.table-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #b5e0f5;
  text-align: center !important;
  border: none !important;
  padding: 20px;
  background-color: #003d6d;
  color: white;
}

.table-head {
  border: none !important;
  border-bottom: none !important;
  padding: 20px !important;
  width: 25%;
}
.sloDashboard__tableData {
  text-align: left;
  vertical-align: middle;
  border: none !important;
  padding-left: 20px !important;
}
.sloDashboard__tableRow {
  border: 0.5px solid #dcdcdc !important;
  /* background-color: #003D6D;
    color: white; */
}
.sloDashBoard__buttons {
  font-size: 14px !important;
  width: 15%;
  background-color: #003d6d;
  border: none;
  color: #ffff !important ;
}

.link {
  text-decoration: none;
  color: #ffff !important;
  width: 100%;

  display: block;
}

.link:hover {
  text-decoration: none;
  color: white;
}
.sloDashBoard__buttons:hover {
  width: 15%;
  background-color: #003d6d !important;
  border: none;
  color: white !important;
}

.sloDashboard__icon {
  font-size: 18px !important;
  color: #003d6d;
}

.sloDashboard__icon:hover {
  cursor: pointer;
}
.sloDashboard__iconButton{
  text-decoration: none;
  border: none;
  background-color: inherit;
}
.sloDashboard__slosTable {
  margin-bottom: 0 !important;
}
.sloDashboard__slosTable > tbody > tr:last-child {
  border-bottom: none !important;
}

.sloDashboard__searchBar {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;

  width: 250px;
  height: 30px;

  /* Functional/Grey-50-White

#FFFFFF
*/
  background: #ffffff;
  /* Primary / Blue-400

#0CA0CE
*/
  border: 1px solid;
  /* Focus/Default/Normal */

  border-radius: 4px;
  font-size: 14px;
}

.sloDashboard__searchBar {
  padding-left: 40px; /* adjust this value based on the size of your icon */
  background-image: url("../Images/mi-magnifying-glass-24px.svg");
  background-repeat: no-repeat;
  background-position: left 10px top 8px;
  background-size: 15px 15px; /* adjust this value based on the size of your icon */
}

/* .sloDashBoard__showSloInfoWithTransition {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.9s ease-out;
}

.sloDashBoard__showSloInfo {
  max-height: 350px;
  overflow-y: auto;
}

.sloDashBoard__showSloInfo:hover {
  overflow-y: auto;
} */

.sloDashboard__loadedSlos {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-left: 40px !important;
  width: 100%;

}


.sloDashboard__slos {

  margin-right: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
   display: flex;
  margin-bottom: 10px;
   width: 98%;

  
  /* padding-left: 20px !important;   */
  border-radius: 5px;
  animation: slideIn 1s ease;
  opacity: 0;
  /* Initially hidden */
  animation-fill-mode: forwards;
  /* font-size: 10px; */
 
}
.sloDashboard__slos:first-child {
  margin-top: 10px;
}
.sloDashboard__ArrowRightIcon {
  color: #003d6d;
  font-size: 25px !important;
  animation-duration: 0.3s;
  cursor: pointer;
}

.sloDashboard__ArrowRightIcon.open {
  animation-name: rotate; /* Apply the animation for smooth rotation */
  animation-fill-mode: forwards; /* Keep the final state of the animation */
}

.sloDashboard__ArrowRightIcon.close {
  animation-name: rotateToInitial; /* Apply the animation for smooth rotation */
  animation-fill-mode: backwards; /* Keep the final state of the animation */
}

.sloDashboard__AddButton{
  animation: slideIn 1s ease;
  opacity: 0;
  /* Initially hidden */
  animation-fill-mode: forwards;
}

@keyframes rotateToInitial {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }

}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
    
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%); /* Start position at the left of the screen */
    opacity: 0;

  }
  to {
    transform: translateX(0%); /* End position at the center of the screen */
    opacity: 1;

  }
}
