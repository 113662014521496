/* 
.navbar{
    padding: 20px 10px 10px 20px;
  }
  .nav-link{
    margin-left: 25px;
    font-weight: 300;
  } */

.headings{
    font-weight: 300;
    font-size: 30px;
    margin-bottom: 15px;
    color: #42b0d5;
    
}
h1{
    font-weight: 300;
   
}
.mainContainer{
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;
    margin-top: 100px;
    text-align: center;
    
}
.topHeading{
    margin-bottom: 50px;
  
}
.contentDiv{
   
    font-size: 18px;
    padding-left: 18%;
    padding-right: 18%;
}
.team{
display: grid;
grid-gap:15px;
margin-top: 24px;
margin-bottom: 24px;
grid-template-columns: repeat(4,2fr);
}
.team-member{
display: flex;
align-items: center;
font-size: 14px;
line-height: 16px;
box-sizing: border-box;
justify-content: center;
/* padding-top: 1rem;
padding-bottom: 1rem; */
text-align: center;
width: 100%;
}
.team-member-image{
display: block;
width: 60px;
height: 60px;
border-radius: 50%;
margin-right: 12px;
border-radius: 20%;
}
.team-member-name{
    display: block;
    font-size: 16px;
line-height: 20px;
}
.team-member-position{
    color: #141414;

}
.teamCard{
    width:20rem !important;
}
.team-link{
    color: #0073ab;
text-decoration: underline;
}
.containerTeam{
    padding-left: 5%;
    /* padding-right: 18%; */
}

@media only screen and (max-width: 1370px) {
    .container-Team-md-screen{
        padding-left:0 !important;
        padding-right: 0 !important;
    }
    .team-member-name{
        display: block;
        font-size: 12px;
    line-height: 20px;
    }
    .team-member{
        display: flex;
        align-items: center;
        font-size: 10px;
        line-height: 16px;
        box-sizing: border-box;
        padding-top: 0rem;
padding-bottom: 0rem;
        }
        .teamCard{
            width:17rem !important;
        }
        .contentDiv{
   
            font-size: 14px;
            padding-left: 18%;
            padding-right: 18%;
        }
       
}