* {
    box-sizing: border-box;
}

.ifNotInMac {
    display: none;
}

.rowDesign {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    justify-content: space-between;
    height: fit-content;
    /* border: 2px solid red; */
    padding-left: 26px;
    padding-right: 26px;
    margin-bottom: 16px;
}

.rowCell {
    width: 48%;
    /* border: 2px solid green; */
}

@media screen and (max-width: 768px) {
    .rowDesign {
        flex-direction: column;
    }

    .rowCell {
        width: 100%;
    }

}

.productForm__input {
    border: 1px solid #CFCFCF;
    border-radius: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    height: 40px;
    padding: 10px;
    width: 100%;


}

.productForm {
    width: 100%;
    border: 1px solid #DCDCDC;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /* align-items: center; */
    padding-top: 20px;
    padding-bottom: 20px;
}

.productForm__inputContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.productForm__label {
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #141414;
}

.choiceDiv__container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
}

.productForm__ChoiceDivUnselected {
    background-color: #EDEDED;
    width: 12%;
    text-align: center;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.productForm__ChoiceDivSelected {
    background-color: #B5E0F5;
    width: 12%;
    text-align: center;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.productForm__submitButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.productForm__submitButton {
    width: 20%;
    font-size: 15px;
    background-color: #B5E0F5;
    border: none;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    ;
}

.productForm__submitButton:hover:enabled {
    background-color: #003D6D;
    color: white;
    cursor: pointer;
}

.invalidInput {
    border-color: red;
}

.sloth__productFormInfo {
    font-size: 10px;
    display: inline-block;
    margin-left: 5px;
}