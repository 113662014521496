.bodyWrapper {
    display: flex;
    flex-direction: column;
    width: 82%;
    margin-left: 16vw;
    margin-top: 5%;
    min-height: 67%;
    max-height: 67%;
    /* border: 2px solid aqua; */
}

.optionSection {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* margin-top: 4vh; */
    flex-wrap: wrap;
    /* min-height: 78vh; */
}

.TabsBox {
    background: #f6fafb;
    /* border: 2px solid #dedada; */
    border-top: 1px solid #dedada;
    /* min-width: 89.2%;
    max-width: 89.2%; */
    /* margin-top: 0.6%; */
    min-height: fit-content;
    padding: 20px;
    border-radius: 2px;
    width: 100%;
    /* border-radius: 2%; */
}

.contents {
    position: relative;
}

.content {
    display: none;
    position: relative;
}


.active-content {
    display: block;
    margin-top: 10px;
    margin-left: 8px;
    /* margin-right: 4px; */
    margin-bottom: 10px;
    transition: all 1s ease-in-out;
    animation: example 0.3s;
}

@media only screen and (min-width:1800px ) and (max-width:2500px) {
    .bodyWrapper{
        margin-top: 3.6% !important;
        /* border: 3px solid red; */
    }
}

@media only screen and (min-width:2501px) {
    .bodyWrapper{
        margin-top: 2% !important;
        /* border: 3px solid green; */
    }
}