.font-20px{
    font-size: 20px;
}
.link-center{
    display: flex;
    justify-content: center;
}
@media only screen and (max-width: 1285px) {
    .card-width-md {
        width:17rem !important
    }
}