.reportSection{
  margin-top: -0%;
  border: 2px solid #DCDCDC;
  max-height: 45rem;
  overflow: auto;
}

.fixedSection{
  margin-top: 4%;
  margin-bottom: 0.6%;
}

table {
    /* font-family: arial, sans-serif; */
    border-collapse: collapse;
    width: 100%;
    /* table-layout: fixed; */
    /* word-wrap: break-word; */
  }
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    /* max-width: 10px; */
    /* overflow: auto; */
    padding: 8px;
  }
  #content{
    width: 595px;
    height: 842px;
  }
  .downloadBtn{
   margin-top: 2%;
   margin-bottom: 2%;
    margin-left: 40%;
    margin-right: 40%;
    background-color:  #42b0d5;

  }

   @media print {
    @page { size: 1800px;
    }
   

  }
 
 