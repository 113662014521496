.ContentWrapper {
    padding: 5%;
    /* border: 1px solid #CFCFCF; */
    margin-top: 6%;
    margin-left: 27%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 56%;
}

.image-logo {
    height: 250px;
    width: 250px;
    position: relative;
    top: -40px
}

.noteText {
    margin-top: 1%;
    font-size: 12px;
    /* word-spacing: 3px; */
    font-weight: bold;
    background-color: #003D6D;
    padding: 6px;
    border-radius: 10px;
    color: white;
}

.innerContent {
    /* border: 2px solid aqua; */
    max-width: 76%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-height: fit-content;
    margin-top: -45px;
}

@media only screen and (max-width: 1300px) {
    .image-logo {
        height: 300px;
        width: 300px;
        position: relative;
        top: -70px
    }

    .ContentWrapper {
        margin-left: 26%;
        /* border: 2px solid purple; */
    }

    .noteText {
        margin-top: 1%;
        font-size: 9px;
        /* word-spacing: 2px; */
        font-weight: bold;
        background-color: #003D6D;
        padding: 6px;
        border-radius: 6px;
        color: white;
    }
}