.reportSection{
    margin-top: -0%;
    border: 2px solid #DCDCDC;
    max-height: 45rem;
    overflow: auto;
  }

.topContainerCert{
    /* background-color:#B5E0F5; */
     display: flex;
     flex-direction: column;
     /* align-items: center; */
     /* min-height: 100vh; */
     margin-top: 80px !important;
}
.batchReport{
    /* background-color:#B5E0F5; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-height: 100vh; */
    margin-top: 80px !important;
}
.submitBtnBatch{
    background-color: #00243D;
}

/* .URL{
    width:70% ;
     border-color:black;
} */

.containDivReport{
    width:600px;
}

.headingURLReport{
    color:#42B0D5;
     /* width:330px; */
     text-align:center;
}
.imgContainerReport{
    background: #DBDBDB;
    border: 1px solid #EDEDED;
    /* Shadows / Box Shadow 8 */
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.04), 0px 6px 30px 5px rgba(0, 0, 0, 0.03), 0px 16px 24px 2px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    align-items: center;
  }
  .thirty{
      background-color: red;
  }
  .sixty{
    background-color: orange;
  }
  .ninety{
      background-color: yellow
  }
  .tablecolor{
      background-color: green;
  }

  .downloadBtn{
    background-color: #003D6D; 
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }
  