.contentContainer {
  width: 84% !important;
}
.headingContainer{
  padding-top: 2% !important;
  margin-top: -8% !important;
}
h5 {
  font-size: 25px;
  margin-bottom: 30px;
}
p {
  margin-bottom: 0;
}
.paraFontSize{
  font-size: 16px;
}
.headingFontSize{
  font-size: 16px;
}
.marginTopContainer{
  margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}
.paddingHeadingContainer{
  padding-top: 1.3rem !important;
  padding-bottom: 1.3rem !important;
}
.paddingTopContainer{
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.marginTopContainerHeading{
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
@media only screen and (max-width: 1285px) {
  .paraFontSize{
    font-size: 9px !important;
  }
 .headingFontSize{
  font-size: 12px !important;
}
  .marginTopContainer{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .paddingHeadingContainer{
    padding-top: .8rem !important;
    padding-bottom: .8rem !important;
  }
  .paddingTopContainer{
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .headingContainer{
    padding-top: 2% !important;
    margin-top: -2% !important;
  }
  .marginTopContainerHeading{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
.containerNew {
  display: flex;
   flex-direction: row;
   align-items: center; 
    height:auto;
    transform: rotate(180deg);
     writing-mode:vertical-rl;
     text-align: center !important;
     justify-content: center !important;
     padding: 20px;
     border-radius: 2%;
}